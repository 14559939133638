import rexManualInspection from '@/assets/rex-clipboard.svg';
import rexCycleCounting from '@/assets/rex-counting-boxes.svg';
import rexLpnStatus from '@/assets/rex-filing-cabinet.svg';
import rexReceiving from '@/assets/rex-forklift.svg';
import rexImageSearch from '@/assets/rex-mag-glass.svg';
import rexIgfgForm from '@/assets/rex-scangun.svg';
import AuthenticatedPage from '@/components/authenticated-page';
import { Card } from '@/components/card';
import { Link } from '@/components/link';
import { pageContentClassName } from '@/utils/class-names';
import './styles/index-page.css';

export { Home as Page };
export const documentProps = {
  title: 'REX App',
};

function Home() {
  return (
    <AuthenticatedPage>
      <main className={pageContentClassName}>
        <Card heading="Redwood EXecution System (REX) Main Menu">
          <ul className="mx-auto flex max-w-screen-lg flex-wrap items-center justify-center p-4">
            <li className="list-style">
              <Link href="/receiving-form/" className="link-style">
                <img className="image-style" src={rexReceiving} alt="REX Receiving" />
                <span>Receiving Form</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/ig-fg-form/" className="link-style">
                <img className="image-style image-scale" src={rexIgfgForm} alt="REX IGFG" />
                <span>IG/FG Form</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/image-search/" className="link-style">
                <img
                  className="image-style image-scale"
                  src={rexImageSearch}
                  alt="LPN Image Search"
                />
                <span>LPN Image Search</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/manual-inspection-form/" className="link-style">
                <img
                  className="image-style"
                  src={rexManualInspection}
                  alt="Bag Manual Inspection"
                />
                <span>Bag Inspection Form</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/cycle-counting-assignment/" className="link-style">
                <img className="image-style" src={rexCycleCounting} alt="Cycle Counting" />
                <span>Cycle Counting Assignment</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/cycle-counting-tasks/" className="link-style">
                <img className="image-style" src={rexCycleCounting} alt="Cycle Counting Tasks" />
                <span>Cycle Counting Tasks</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/cycle-counting-problem-tasks/" className="link-style">
                <img
                  className="image-style"
                  src={rexCycleCounting}
                  alt="Cycle Counting Problem Tasks"
                />
                <span>Cycle Counting Problem Tasks</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/lpn-status/" className="link-style">
                <img className="image-style" src={rexLpnStatus} alt="LPN Status" />
                <span>LPN Status</span>
              </Link>
            </li>
            <li className="list-style">
              <Link href="/icp-upload/" className="link-style">
                <img className="image-style" src={rexLpnStatus} alt="ICP Upload" />
                <span>ICP Upload</span>
              </Link>
            </li>
          </ul>
        </Card>
      </main>
    </AuthenticatedPage>
  );
}
